import React, {FC} from "react";
import {Helmet} from "react-helmet";
import LoraText from "../components/LoraText";
import LoraHead from "../components/LoraHead";
import styles from "../styles/pages/ProductsPage.module.scss";
import {PAGE_NAME} from "../layout/config";
import LoraButton from "../components/LoraButton";

const hostname = window.location.protocol + "//" + window.location.hostname;

const ProductsPage: FC = () => {
	return (
		<>
			<Helmet>
				<title>Produkty | {PAGE_NAME}</title>
				<meta name="description" content="Vytvořili jsme software, který umožňuje nastavení výkonu a ovládání chromatičnosti osvětlení na požadovanou změnu barev a intenzity osvětlení, ale dochází také k velké k úspoře energie."/>
				<meta property="og:title" content="Produkty | {PAGE_NAME}"/>
				<meta property="og:description" content="Vytvořili jsme software, který umožňuje nastavení výkonu a ovládání chromatičnosti osvětlení na požadovanou změnu barev a intenzity osvětlení, ale dochází také k velké k úspoře energie."/>
				<link rel="canonical" href={hostname + "/produkty"}/>
				<link rel="alternate" hrefLang="cs-cs" href={hostname + "/produkty"}/>
			</Helmet>

			<main className={styles.products}>
				<div className="bg-primary padding-top-60 padding-bottom-60">
					<div className="container">
						<div className="grid">
							<div className="col col-12">
								<LoraHead loraElement="h1" loraColor="secondary">
									SMART CITY
								</LoraHead>
								<LoraText loraStyle="longtext">
									Koncept Smart city „chytrého města“ představuje platformu pro služby s podporou IT. Chytrá města využívají ke sběru a analýze dat zařízení IoT, jako jsou připojené senzory, světla a měřiče. Města pak tato data
									využívají ke zlepšení infrastruktury a veřejných služeb. Chytrá města poskytují svým obyvatelům efektivnější a kvalitnější životní styl.
								</LoraText>
								<LoraText loraStyle="longtext">Technologie Smart City usnadňují a zefektivňují každodenní činnosti související s veřejnou bezpečností, dopravou a problémy životního prostředí.</LoraText>
							</div>

							<div className="col col-12">
								<LoraText loraCustomClass="text-center">
									<LoraButton loraVariant="text-secondary" path="/produkty/dali-light-control">
										LoRaWAN DALI LightControl
									</LoraButton>
								</LoraText>
								<LoraText loraCustomClass="text-center margin-bottom-60">
									<img src={"./klobouk1.png"} width={354} height={255} alt={"LoRaWAN DALI LightControl"}/>
								</LoraText>
							</div>

							<div className="col col-12">
								<LoraHead loraElement="h2" loraColor="secondary" loraStyle="h1">
									Smart city - veřejné osvětlení (VO)
								</LoraHead>
								<LoraText loraStyle="longtext">
									Chytrá osvětlení IoT je systém postavený na architektuře internetu věcí (IoT) a skládá se ze sítě LED světel a patentovaných senzorů, připojených k pokročilé analytické platformě. Využívá bezdrátové spínače, čímž
									odpadá nutnost zapojovat spínače osvětlení přímo do svítidel. Světla jsou připojena k síti, což umožňuje jejich monitorování a ovládání z cloudu.
								</LoraText>
								<LoraText loraStyle="longtext">
									Chytrá osvětlení regulují intenzitu jasu a stmívat v závislosti na denní době a okolním provozu. Spolu s využitím LED světlených zdrojů výrazně napomáhají k úspoře energie a šetrnosti k životnímu prostředí. Světla
									rychle a jednoduše komunikují s centrálním ovládáním v případě poruchy, navíc mohou být efektivně ovládané na dálku.
								</LoraText>
								<LoraText loraStyle="longtext">
									Na stožáry veřejného osvětlení můžeme mimo jiné připojit např. meteostanice, nouzová tlačítka pro přivolání pomoci, různé senzory apod. To umožní přispívat ke zvyšování komfortu, informovanosti a především bezpečí
									v ulicích chytrého města a obcí.
								</LoraText>
								<LoraText loraCustomClass="text-center margin-bottom-60">
									<img src={"../klobouk2.png"} width={365} height={261} alt={"LoRaWAN DALI LightControl"}/>
								</LoraText>
							</div>

							<div className="col col-12">
								<LoraHead loraElement="h2" loraColor="secondary" loraStyle="h1">
									Chromatičnost
								</LoraHead>
								<LoraText loraStyle="longtext">
									Umělé osvětlení hraje v životech nás všech především v podzimních a zimních měsících velmi důležitou roli. Mělo by být vždy vhodně přizpůsobeno prostoru, vykonávané činnosti a dalším aktuálním potřebám těch, kdo se
									v jeho blízkosti nachází. Při výběru svítidel často klademe důraz na intenzitu světla, které zvolený zdroj vyzařuje. Důležité je ale také sledovat barvu světla neboli teplotu chromatičnosti.
								</LoraText>
								<LoraText loraStyle="longtext">
									<b>Teplota chromatičnosti</b> (někdy nazývaná barevná teplota) je pojem, kterým se odborně označují různé odstíny vyzařované svítidly a světelnými zdroji. Měří se v Kelvinech (K) a obvykle dosahuje hodnot od cca
									1800K (temně oranžová až červená) po 8000K (téměř světle modrá)
								</LoraText>
								<LoraText loraStyle="longtext">
									Bílé světlo můžeme všeobecně rozdělit na teplé, studené a neutrální. Každý z těchto tónů ovlivňuje vnímání okolí, náladu a celkovou atmosféru jiným způsobem. Studené světlo stimuluje smysly a podporuje schopnost
									soustředit se, zatímco teplé světlo napomáhá navodit pocity relaxace, odpočinku a spánku.
								</LoraText>
								<LoraText loraStyle="longtext">
									Vytvořili jsme software, který umožňuje nastavení výkonu a ovládání chromatičnosti osvětlení na požadovanou změnu barev a intenzity osvětlení, ale dochází také k velké k úspoře energie.
								</LoraText>
								<LoraText loraCustomClass="text-center margin-bottom-60">
									<img src={"../klobouk3.png"} width={322} height={282} alt={"LoRaWAN DALI LightControl"}/>
								</LoraText>
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

export default ProductsPage;
