import React, {FC} from "react";
import {Helmet} from "react-helmet";
import LoraText from "../components/LoraText";
import LoraHead from "../components/LoraHead";
import styles from "../styles/pages/ProductsPage.module.scss";
import {PAGE_NAME} from "../layout/config";

const hostname = window.location.protocol + "//" + window.location.hostname;

const ProductDaliLightControlPage: FC = () => {
	return (
		<>
			<Helmet>
				<title>LoRaWAN DALI LightControl | Produkty | {PAGE_NAME}</title>
				<meta name="description" content="LoRaWAN DALI LightControl zařízení bylo navrženo pro dálkové ovládání veřejného osvětlení, které je osazeno moderními LED tělesy řízenými pomocí napájecích jednotek s implementovaným DALI protokolem." />
				<meta property="og:title" content="LoRaWAN DALI LightControl | Produkty | {PAGE_NAME}" />
				<meta property="og:description" content="LoRaWAN DALI LightControl zařízení bylo navrženo pro dálkové ovládání veřejného osvětlení, které je osazeno moderními LED tělesy řízenými pomocí napájecích jednotek s implementovaným DALI protokolem." />
				<link rel="canonical" href={hostname + "/produkty/dali-light-control"} />
				<link rel="alternate" hrefLang="cs-cs" href={hostname + "/produkty/dali-light-control"} />
			</Helmet>

			<main className={styles.products}>
				<div className="bg-primary padding-top-60 padding-bottom-60">
					<div className="container">
						<div className="grid">
							<div className="col col-12">
								<LoraHead loraElement="h1" loraColor="secondary">
									LoRaWAN DALI LightControl
								</LoraHead>
								<LoraText>
									<img src={"../klobouk1.png"} width={354} height={255} alt={"LoRaWAN DALI LightControl"}/>
								</LoraText>
								<LoraText loraStyle="longtext">
									LoRaWAN DALI LightControl zařízení bylo navrženo pro dálkové ovládání veřejného osvětlení, které je osazeno moderními LED tělesy řízenými pomocí napájecích jednotek s implementovaným DALI protokolem.
								</LoraText>
								<LoraText loraStyle="longtext">LoRaWAN DALI LightControl zařízení je konstruováno pro standardizované patice TE Connectivity LUMAWISE 24V a splňuje požadavky na řízení pomocí DALI protokolu.
									Zařízení dokáže pracovat jak ve slave, tak v master režimu. Tyto režimy jsou automaticky detekovány při instalaci.
									V principu se jedná o LoRaWAN Class C zařízení, které je permanentně na napájeno z DALI patice a je-li to vyžadováno dokáže zprostředkovat měkký proudový zdroj pro DALI sběrnici a zajistit tak master mód.
									Je podporováno unicastové I multicastové řízení s rozdělením do až 64 subskupin. Pro účely pasportizace je možné volitelně doplnit GSP modul.
								</LoraText>
								<LoraText loraCustomClass="text-left margin-bottom-60">
									<img src={"../solidus.png"} width={360} height={125} alt={"Solidus Tech s.r.o."}/>
								</LoraText>
								<LoraText loraStyle="longtext">
									Vlastnosti
									<ul>
										<li>IP65 ochrana v TE Connectivity pouzdře</li>
										<li>Implementovány příkazy pro DALI protokol</li>
										<li>Podpora transparentního řízení DALI</li>
										<li>Adresace pro až15 DALI zdrojů z jednoho zařízení</li>
										<li>Až  další 50% úspora energie oproti prosté náhradě sodíkových výbojek za LED tělesa</li>
										<li>Pokročilá rádiová technologie pro bezproblémový provoz</li>
										<li>Plně autonomní mód při ztrátě komunikace</li>
										<li>Integrovaný superkapacitor pro reporting ztráty napájení a jiných poruchových stavů</li>
										<li>Dostupný SW pro řízení osvětlení obce/města, viz. kopie obrazovek níže</li>
										<li>Otestováno na 3 pilotních projektech s nasazením 800+ zařízení</li>
									</ul>
								</LoraText>
								<LoraText loraStyle="longtext">
									<table>
										<thead>
											<th>LPWAN LightCOntrol DALI device</th>
											<th>Parameter</th>
										</thead>
										<tbody>
											<tr>
												<td>Protection</td>
												<td>IP65, IP65 with goretex membrane on demand, transparent paint</td>
											</tr>
											<tr>
												<td>Operating temperature</td>
												<td>-40 to +80°C</td>
											</tr>
											<tr>
												<td>Power supply</td>
												<td>24V DALI bus, slave and master mode auto detection</td>
											</tr>
											<tr>
												<td>Power consumption</td>
												<td>70mW receiving enabled mode, 210mW transmission peak</td>
											</tr>
											<tr>
												<td>Supported LPWAN standards</td>
												<td>LoRaWAN</td>
											</tr>
											<tr>
												<td>Dimensions in mm</td>
												<td>Diameter 80mm, heigh 40mm</td>
											</tr>
											<tr>
												<td>Weight</td>
												<td>130g</td>
											</tr>
											<tr>
												<td>Protocols</td>
												<td>LoRaWAN Class C,  DALI, GPS</td>
											</tr>
											<tr>
												<td>Antenna/typical range</td>
												<td>Helical, gain 2.5dBi, range 35km</td>
											</tr>
										</tbody>
									</table>
									<LoraText loraCustomClass="margin-bottom-60">
										<img src={"../klobouk2.png"} width={365} height={261} alt={"LoRaWAN DALI LightControl"}/>
										<img src={"../klobouk3.png"} width={322} height={282} alt={"LoRaWAN DALI LightControl"}/>
									</LoraText>
								</LoraText>
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

export default ProductDaliLightControlPage;
