import React, { FC, ReactNode } from "react";
import styles from "../styles/components/LoraHead.module.scss";
import clsx from "clsx";

type LoraHeadColor = "white" | "primary" | "secondary";
type LoraHeadStyle = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
type LoraHeadElement = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

interface LoraHeadProps {
	children?: ReactNode;
	loraColor?: LoraHeadColor;
	loraStyle?: LoraHeadStyle;
	loraCustomClass?: string;
	loraElement: LoraHeadElement;
}

const LoraHead: FC<LoraHeadProps> = (props) => {
	const loraStyle = props.loraStyle ?? props.loraElement;

	const useColor = () => {
		switch (props.loraColor) {
			case "white":
				return styles.headWhite;
			case "primary":
				return styles.headPrimary;
			case "secondary":
				return styles.headSecondary;
			default:
				return styles.headSecondary;
		}
	};

	const useStyle = () => {
		switch (loraStyle) {
			case "h1":
				return styles.h1;
			case "h2":
				return styles.h2;
			case "h3":
				return styles.h3;
			case "h4":
				return styles.h4;
			case "h5":
				return styles.h5;
			case "h6":
				return styles.h6;
		}
	};

	let loraHeadColorClassName = useColor();
	let loraHeadStyleClassName = useStyle();

	return (
		<>
			<props.loraElement className={clsx(styles.head, loraHeadColorClassName, loraHeadStyleClassName, props.loraCustomClass)}>{props.children}</props.loraElement>
		</>
	);
};

export default LoraHead;
