import React, {FC} from "react";
import {Helmet} from "react-helmet";
import LoraHead from "../components/LoraHead";
import styles from "../styles/pages/ReferencesPage.module.scss";
import {PAGE_NAME} from "../layout/config";

const hostname = window.location.protocol + "//" + window.location.hostname;

const ReferencesPage: FC = () => {
	return (
		<>
			<Helmet>
				<title>Reference | {PAGE_NAME}</title>
				<meta name="description" content="Několik ukázek z našich realizací" />
				<meta property="og:title" content="Reference | {PAGE_NAME}" />
				<meta property="og:description" content="Několik ukázek z našich realizací" />
				<link rel="canonical" href={hostname + "/reference"} />
				<link rel="alternate" hrefLang="cs-cs" href={hostname + "/reference"} />
			</Helmet>

			<main className={styles.references}>
				<div className="bg-primary padding-top-60 padding-bottom-60">
					<div className="container">
						<LoraHead loraElement="h1" loraColor="secondary">
							Připravujeme...
						</LoraHead>
						{/*<LoraHead loraElement="h1" loraColor="secondary">
							Několik ukázek z našich realizací
						</LoraHead>
						<div className="grid margin-bottom-60">
							<div className="col col-12">
								<ul className="margin-top-0 margin-bottom-0">
									<li>
										<LoraHead loraElement="h3" loraColor="white" loraStyle="h5" loraCustomClass="margin-bottom-0">
											Boží dar
										</LoraHead>
										<LoraText loraCustomClass="margin-bottom-0">Osvětlení kostela.</LoraText>
									</li>
								</ul>
							</div>
							<div className="col col-12">
								<ul className="margin-top-0 margin-bottom-0">
									<li>
										<LoraHead loraElement="h3" loraColor="white" loraStyle="h5" loraCustomClass="margin-bottom-0">
											Pozořice
										</LoraHead>
									</li>
								</ul>
							</div>
							<div className="col col-12">
								<ul className="margin-top-0 margin-bottom-0">
									<li>
										<LoraHead loraElement="h3" loraColor="white" loraStyle="h5" loraCustomClass="margin-bottom-0">
											ELKO
										</LoraHead>
									</li>
								</ul>
							</div>
							<div className="col col-12">
								<ul className="margin-top-0 margin-bottom-0">
									<li>
										<LoraHead loraElement="h3" loraColor="white" loraStyle="h5" loraCustomClass="margin-bottom-0">
											Spolupráce a vývoj hardwaru pro ovládání se společností Solidus Tech s.r.o.
										</LoraHead>
									</li>
								</ul>
							</div>
							<div className="col col-12">
								<ul className="margin-top-0 margin-bottom-0">
									<li>
										<LoraHead loraElement="h3" loraColor="white" loraStyle="h5" loraCustomClass="margin-bottom-0">
											Spolupráce s městy
										</LoraHead>
									</li>
								</ul>
							</div>
						</div>

						<LoraText loraStyle="longtext">
							Vývoj není o využití pouze jedné technologie, ale o schopnosti navrhnout možná řešení za využití několika technologií, jejich otestování, a vybrání vhodné varianty k následné realizaci.
						</LoraText>
						<LoraText loraCustomClass="text-center">
							<LoraButton loraVariant="text-secondary" path="/kontakt">
								Ozvěte se nám s problémem, který řešíte a pomůžeme Vám přijít s řešením.
							</LoraButton>
						</LoraText>*/}
					</div>
				</div>
			</main>
		</>
	);
};

export default ReferencesPage;
