import React, { FC } from "react";
import LoraIllustration from "../components/LoraIllustration";
import LoraPreHead from "../components/LoraPreHead";
import LoraHead from "../components/LoraHead";
import LoraText from "../components/LoraText";
import LoraButton from "../components/LoraButton";
import styles from "../styles/pages/StyleguidePage.module.scss";

const HomePage: FC = () => {
	return (
		<>
			<main className={styles.styleguide}>
				<div className="bg-primary padding-top-60 padding-top-150@lg padding-bottom-60 padding-bottom-150@lg">
					<div className="container">
						<div className="grid">
							<div className="col col-12 col-6@lg">
								<LoraIllustration />
							</div>
							<div className="col col-middle col-12 col-6@lg">
								<LoraPreHead>Představujeme vám</LoraPreHead>
								<LoraHead loraElement="h1" loraColor="white">
									Spolehlivý IOT systém pro Vaši firmu
								</LoraHead>
								<LoraText loraCustomClass="margin-bottom-60" loraStyle="longtext">
									Jsme inovativní česká společnost zabývající se vývojem softwaru IoT v oblasti Veřejného osvětlení a senzoriky.
								</LoraText>
								<LoraText loraCustomClass="text-center text-left@lg">
									<LoraButton loraVariant="primary" path="/o-nas">
										Zjistit více
									</LoraButton>
								</LoraText>
							</div>
						</div>
					</div>
				</div>

				<div className="bg-primary-light padding-top-60 padding-top-150@lg padding-bottom-60 padding-bottom-150@lg">
					<div className="container">
						<LoraPreHead>Nabídka služeb</LoraPreHead>
						<LoraHead loraElement="h2" loraColor="primary">
							Přinášíme do firem kontrolu věcí
						</LoraHead>
						<div className="grid">
							<div className="col col-12 col-6@lg">
								<div className="bg-primary padding-30 padding-50@lg">
									<LoraHead loraElement="h3" loraColor="white" loraStyle="h4">
										Řešení
									</LoraHead>
									<LoraText>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
									<LoraText loraCustomClass="margin-bottom-0 text-center text-right@sm">
										<LoraButton loraVariant="primary" path="/reseni">
											Více
										</LoraButton>
									</LoraText>
								</div>
							</div>
							<div className="col col-12 col-6@lg">
								<div className="bg-primary padding-30 padding-50@lg">
									<LoraHead loraElement="h3" loraColor="white" loraStyle="h4">
										Produkty
									</LoraHead>
									<LoraText>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
									<LoraText loraCustomClass="margin-bottom-0 text-center text-right@sm">
										<LoraButton loraVariant="primary" path="/produkty">
											Více
										</LoraButton>
									</LoraText>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="bg-white padding-top-60 padding-top-150@lg padding-bottom-60 padding-bottom-150@lg">
					<div className="container">
						<LoraPreHead>Naše Přednosti</LoraPreHead>
						<LoraHead loraElement="h2" loraColor="primary">
							Co vám přinese spolupráce s námi?
						</LoraHead>
						<div className="grid">
							<div className="col col-12 col-6@lg">
								<div className="padding-30 padding-40@lg shadow-1">
									<LoraHead loraElement="h3" loraColor="secondary" loraStyle="h5">
										IOT systém s českým prostředím
									</LoraHead>
									<LoraText>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
								</div>
							</div>
							<div className="col col-12 col-6@lg">
								<div className="padding-30 padding-40@lg shadow-1">
									<LoraHead loraElement="h3" loraColor="secondary" loraStyle="h5">
										IOT systém s českým prostředím
									</LoraHead>
									<LoraText>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
								</div>
							</div>
							<div className="col col-12 col-6@lg">
								<div className="padding-30 padding-40@lg shadow-1">
									<LoraHead loraElement="h3" loraColor="secondary" loraStyle="h5">
										IOT systém s českým prostředím
									</LoraHead>
									<LoraText>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
								</div>
							</div>
							<div className="col col-12 col-6@lg">
								<div className="padding-30 padding-40@lg shadow-1">
									<LoraHead loraElement="h3" loraColor="secondary" loraStyle="h5">
										IOT systém s českým prostředím
									</LoraHead>
									<LoraText>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
								</div>
							</div>
						</div>
						<LoraText loraCustomClass="margin-top-60 margin-bottom-0 text-center">
							<LoraButton loraVariant="secondary" path="/o-nas">
								Více o nás
							</LoraButton>
						</LoraText>
					</div>
				</div>

				<div className="bg-primary-light padding-top-60 padding-top-150@lg padding-bottom-60 padding-bottom-150@lg">
					<div className="container">
						<LoraHead loraElement="h2" loraColor="primary">
							Podívejte se, jak náš IOT systém usnadnil práci firmám
						</LoraHead>
						<div className="grid">
							<div className="col col-12">
								<div className="bg-white padding-40 padding-80@lg">
									<LoraHead loraElement="h3" loraColor="secondary" loraStyle="h5">
										IOT systém V českém pivovaru Pilsner urquell
									</LoraHead>
									<LoraText>
										{
											"Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra."
										}
									</LoraText>
									<LoraText loraCustomClass="margin-bottom-0 text-right">
										<LoraButton loraVariant="text-primary" path="/kontakt">
											Přečíst celý článek
										</LoraButton>
									</LoraText>
								</div>
							</div>
						</div>
						<LoraText loraCustomClass="margin-top-60 margin-bottom-0 text-center">
							<LoraButton loraVariant="secondary" path="/reference">
								Všechny reference
							</LoraButton>
						</LoraText>
					</div>
				</div>

				<div className="bg-white padding-top-60 padding-top-150@lg padding-bottom-60 padding-bottom-150@lg">
					<div className="container">
						<LoraPreHead>Kontaktujte nás</LoraPreHead>
						<LoraHead loraElement="h2" loraColor="primary">
							Potřebujete od nás poradit nebo doporučit ideální řešení?
						</LoraHead>
						<LoraText loraCustomClass="margin-bottom-60">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
						<LoraText loraCustomClass="text-center text-left@lg">
							<LoraButton loraVariant="primary" path="/kontakt">
								Zobrazit kontakty
							</LoraButton>
						</LoraText>
					</div>
				</div>

				<div className="bg-primary-light padding-top-60 padding-top-150@lg padding-bottom-60 padding-bottom-150@lg">
					<div className="container">
						<LoraHead loraElement="h2" loraColor="primary">
							Přehled buttonů
						</LoraHead>
						<LoraText>
							<LoraButton loraVariant="primary" path="/kontakt">
								primary
							</LoraButton>
						</LoraText>
						<LoraText>
							<LoraButton loraVariant="secondary" path="/kontakt">
								secondary
							</LoraButton>
						</LoraText>
						<LoraText>
							<LoraButton loraVariant="text-primary" path="/kontakt">
								text-primary
							</LoraButton>
						</LoraText>
						<LoraText>
							<LoraButton loraVariant="text-secondary" path="/kontakt">
								text-secondary
							</LoraButton>
						</LoraText>
						<LoraText>
							<LoraButton path="/kontakt">{"default"}</LoraButton>
						</LoraText>
					</div>
				</div>

				<div className="bg-primary">
					<div className="container padding-30">
						<LoraText loraCustomClass="bg-white padding-30 margin-bottom-0">
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora
							torquent per conubia nostra.
						</LoraText>
					</div>
				</div>

				<div className="bg-white padding-top-20 padding-top-60@lg padding-bottom-20 padding-bottom-60@lg">
					<div className="container">
						<LoraText loraCustomClass="bg-primary padding-30">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
						<LoraText loraCustomClass="bg-primary-light padding-30">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
						<LoraText>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
					</div>
					<div className="container">
						<div className="bg-primary">
							<LoraText loraCustomClass="bg-white">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
						</div>
					</div>
				</div>

				<div className="bg-primary">
					<div className="container padding-30">
						<LoraText loraCustomClass="bg-white padding-30 margin-bottom-0">
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora
							torquent per conubia nostra.
						</LoraText>
						<LoraText loraCustomClass="bg-white padding-30 margin-bottom-20">
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora
							torquent per conubia nostra.
						</LoraText>
						<LoraText loraCustomClass="bg-white padding-30 margin-bottom-60">
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora
							torquent per conubia nostra.
						</LoraText>
						<LoraText loraCustomClass="bg-white padding-30 margin-bottom-150">
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora
							torquent per conubia nostra.
						</LoraText>
					</div>
				</div>

				<div className="bg-primary-light">
					<div className="container padding-30">
						<LoraText loraCustomClass="bg-primary padding-30 margin-top-0">
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora
							torquent per conubia nostra.
						</LoraText>
						<LoraText loraCustomClass="bg-primary padding-30 margin-top-20">
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora
							torquent per conubia nostra.
						</LoraText>
						<LoraText loraCustomClass="bg-primary padding-30 margin-top-60">
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora
							torquent per conubia nostra.
						</LoraText>
						<LoraText loraCustomClass="bg-primary padding-30 margin-top-150">
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora
							torquent per conubia nostra.
						</LoraText>
					</div>
				</div>

				<div className="bg-primary">
					<div className="container padding-30">
						<LoraText loraCustomClass="bg-white padding-top-0">
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora
							torquent per conubia nostra.
						</LoraText>
						<LoraText loraCustomClass="bg-white padding-top-20">
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora
							torquent per conubia nostra.
						</LoraText>
						<LoraText loraCustomClass="bg-white padding-top-60">
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora
							torquent per conubia nostra.
						</LoraText>
						<LoraText loraCustomClass="bg-white padding-top-150">
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora
							torquent per conubia nostra.
						</LoraText>
					</div>
				</div>

				<div className="bg-primary-light">
					<div className="container padding-30">
						<LoraText loraCustomClass="bg-primary padding-bottom-0">
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora
							torquent per conubia nostra.
						</LoraText>
						<LoraText loraCustomClass="bg-primary padding-bottom-20">
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora
							torquent per conubia nostra.
						</LoraText>
						<LoraText loraCustomClass="bg-primary padding-bottom-60">
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora
							torquent per conubia nostra.
						</LoraText>
						<LoraText loraCustomClass="bg-primary padding-bottom-150">
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora
							torquent per conubia nostra.
						</LoraText>
					</div>
				</div>

				<div className="bg-primary">
					<div className="container padding-30">
						<LoraText loraCustomClass="bg-white padding-0">
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora
							torquent per conubia nostra.
						</LoraText>
						<LoraText loraCustomClass="bg-white padding-30">
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora
							torquent per conubia nostra.
						</LoraText>
						<LoraText loraCustomClass="bg-white padding-40">
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora
							torquent per conubia nostra.
						</LoraText>
						<LoraText loraCustomClass="bg-white padding-50">
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora
							torquent per conubia nostra.
						</LoraText>
						<LoraText loraCustomClass="bg-white padding-80">
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora
							torquent per conubia nostra.
						</LoraText>
					</div>
				</div>

				<div className="bg-white padding-top-60 padding-bottom-60">
					<div className="container">
						<div className="grid">
							<div className="col col-12 col-6@lg">
								<LoraText loraCustomClass="margin-bottom-0">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
							</div>
							<div className="col col-12 col-6@lg">
								<LoraText loraCustomClass="margin-bottom-0">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
							</div>
						</div>
					</div>
				</div>

				<div className="bg-primary padding-top-60 padding-bottom-60">
					<div className="container">
						<div className="grid">
							<div className="col col-12 col-6@lg">
								<LoraText loraCustomClass="bg-primary-light padding-30 margin-bottom-0">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
							</div>
							<div className="col col-12 col-6@lg">
								<LoraText loraCustomClass="bg-primary-light padding-30 margin-bottom-0">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
							</div>
						</div>
					</div>
				</div>

				<div className="bg-primary-light padding-top-60 padding-bottom-60">
					<div className="container">
						<div className="grid">
							<div className="col col-12 col-6@lg">
								<LoraText loraCustomClass="text-center">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
								<LoraText loraCustomClass="text-center">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
								<LoraText loraCustomClass="text-center margin-bottom-0">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
							</div>
							<div className="col col-12 col-6@lg">
								<LoraText loraCustomClass="text-center">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
								<LoraText loraCustomClass="text-center">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
								<LoraText loraCustomClass="text-center margin-bottom-0">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
							</div>
						</div>
					</div>
				</div>

				<div className="bg-primary padding-top-60 padding-bottom-60">
					<div className="container">
						<div className="grid">
							<div className="col col-12 col-6@lg">
								<LoraText loraCustomClass="text-center">
									<img src="../solidus.png" width="360" height="125" alt="alt by bylo fajn vyplnit" />
								</LoraText>
							</div>
							<div className="col col-12 col-6@lg">
								<LoraText loraCustomClass="text-center">
									<img src="../solidus.png" width="360" height="125" alt="alt by bylo fajn vyplnit" />
								</LoraText>
							</div>
						</div>
					</div>
				</div>

				<div className="bg-primary-light padding-top-60 padding-bottom-60">
					<div className="container">
						<LoraText loraCustomClass="text-center margin-bottom-0">
							<img src="../solidus.png" width="360" height="125" alt="alt by bylo fajn vyplnit" />
						</LoraText>
					</div>
				</div>

				<div className="bg-primary padding-top-60 padding-bottom-60">
					<div className="container">
						<div className="grid">
							<div className="col col-12 col-6@lg">
								<LoraText loraCustomClass="text-center bg-white padding-0">
									<img src="../solidus.png" width="360" height="125" alt="alt by bylo fajn vyplnit" />
								</LoraText>
								<LoraText loraCustomClass="text-center bg-white padding-30">
									<img src="../solidus.png" width="360" height="125" alt="alt by bylo fajn vyplnit" />
								</LoraText>
								<LoraText loraCustomClass="text-center bg-white padding-40">
									<img src="../solidus.png" width="360" height="125" alt="alt by bylo fajn vyplnit" />
								</LoraText>
								<LoraText loraCustomClass="text-center bg-white padding-50">
									<img src="../solidus.png" width="360" height="125" alt="alt by bylo fajn vyplnit" />
								</LoraText>
								<LoraText loraCustomClass="text-center bg-white padding-80 margin-bottom-0">
									<img src="../solidus.png" width="360" height="125" alt="alt by bylo fajn vyplnit" />
								</LoraText>
							</div>
							<div className="col col-12 col-6@lg">
								<LoraText loraCustomClass="text-center bg-white padding-0">
									<img src="../solidus.png" width="360" height="125" alt="alt by bylo fajn vyplnit" />
								</LoraText>
								<LoraText loraCustomClass="text-center bg-white padding-30">
									<img src="../solidus.png" width="360" height="125" alt="alt by bylo fajn vyplnit" />
								</LoraText>
								<LoraText loraCustomClass="text-center bg-white padding-40">
									<img src="../solidus.png" width="360" height="125" alt="alt by bylo fajn vyplnit" />
								</LoraText>
								<LoraText loraCustomClass="text-center bg-white padding-50">
									<img src="../solidus.png" width="360" height="125" alt="alt by bylo fajn vyplnit" />
								</LoraText>
								<LoraText loraCustomClass="text-center bg-white padding-80 margin-bottom-0">
									<img src="../solidus.png" width="360" height="125" alt="alt by bylo fajn vyplnit" />
								</LoraText>
							</div>
						</div>
					</div>
				</div>

				<div className="bg-primary-light padding-bottom-60">
					<div className="container">
						<LoraText loraCustomClass="text-center bg-primary margin-bottom-0">
							<img src="../solidus.png" width="360" height="125" alt="alt by bylo fajn vyplnit" />
						</LoraText>
						<LoraText loraCustomClass="text-center bg-primary margin-bottom-20">
							<img src="../solidus.png" width="360" height="125" alt="alt by bylo fajn vyplnit" />
						</LoraText>
						<LoraText loraCustomClass="text-center bg-primary margin-bottom-60">
							<img src="../solidus.png" width="360" height="125" alt="alt by bylo fajn vyplnit" />
						</LoraText>
						<LoraText loraCustomClass="text-center bg-primary margin-bottom-150">
							<img src="../solidus.png" width="360" height="125" alt="alt by bylo fajn vyplnit" />
						</LoraText>
						<LoraText loraCustomClass="text-center bg-primary margin-top-0">
							<img src="../solidus.png" width="360" height="125" alt="alt by bylo fajn vyplnit" />
						</LoraText>
						<LoraText loraCustomClass="text-center bg-primary margin-top-20">
							<img src="../solidus.png" width="360" height="125" alt="alt by bylo fajn vyplnit" />
						</LoraText>
						<LoraText loraCustomClass="text-center bg-primary margin-top-60">
							<img src="../solidus.png" width="360" height="125" alt="alt by bylo fajn vyplnit" />
						</LoraText>
						<LoraText loraCustomClass="text-center bg-primary margin-top-150 margin-bottom-0">
							<img src="../solidus.png" width="360" height="125" alt="alt by bylo fajn vyplnit" />
						</LoraText>
					</div>
				</div>

				<div className="bg-white padding-top-60 padding-bottom-60">
					<div className="container">
						<LoraText loraCustomClass="text-left">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
					</div>
				</div>

				<div className="bg-primary padding-top-60 padding-bottom-60">
					<div className="container">
						<LoraText loraCustomClass="text-center">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
					</div>
				</div>

				<div className="bg-primary-light padding-top-60 padding-bottom-60">
					<div className="container">
						<LoraText loraCustomClass="text-right">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
					</div>
				</div>

				<div className="bg-primary padding-top-60 padding-bottom-60">
					<div className="container">
						<LoraText loraCustomClass="max-width-6-col bg-white">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
					</div>
				</div>

				<div className="bg-primary-light padding-top-60 padding-bottom-60">
					<div className="container">
						<LoraText loraCustomClass="max-width-4-col bg-white margin-x-auto">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
					</div>
				</div>

				<div className="bg-primary padding-top-60 padding-bottom-60">
					<div className="container">
						<LoraText loraCustomClass="max-width-1-col bg-white margin-x-auto">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
						<LoraText loraCustomClass="max-width-2-col bg-white margin-x-auto">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
						<LoraText loraCustomClass="max-width-3-col bg-white margin-x-auto">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
						<LoraText loraCustomClass="max-width-4-col bg-white margin-x-auto">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
						<LoraText loraCustomClass="max-width-5-col bg-white margin-x-auto">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
						<LoraText loraCustomClass="max-width-6-col bg-white margin-x-auto">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
						<LoraText loraCustomClass="max-width-7-col bg-white margin-x-auto">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
						<LoraText loraCustomClass="max-width-8-col bg-white margin-x-auto">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
						<LoraText loraCustomClass="max-width-9-col bg-white margin-x-auto">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
						<LoraText loraCustomClass="max-width-10-col bg-white margin-x-auto">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
						<LoraText loraCustomClass="max-width-11-col bg-white margin-x-auto">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
						<LoraText loraCustomClass="max-width-12-col bg-white margin-x-auto">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</LoraText>
					</div>
				</div>
			</main>
		</>
	);
};

export default HomePage;
