import React, { FC, ReactNode } from "react";
import styles from "../styles/components/LoraPreHead.module.scss";
import clsx from "clsx";

interface LoraPreHeadProps {
	children?: ReactNode;
	loraCustomClass?: string;
}

const LoraPreHead: FC<LoraPreHeadProps> = (props) => {
	return (
		<>
			<em className={clsx(styles.default, props.loraCustomClass)}>{props.children}</em>
		</>
	);
};

export default LoraPreHead;
