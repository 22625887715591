import React, { FC } from "react";
import styles from "../styles/components/LoraIllustration.module.scss";

const LoraIllustration: FC = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 461.81 481.26" className={styles.illustration}>
			<defs>
				<linearGradient id="gradient" x1="-626.111" x2="-624.391" y1="-280.017" y2="-277.547" gradientTransform="matrix(89.53 0 0 29.16 56174.258 8390.444)" gradientUnits="userSpaceOnUse">
					<stop offset="0" stopColor="#00ff86" />
					<stop offset=".51" stopColor="#52a7a9" />
					<stop offset="1" stopColor="#247ca4" />
				</linearGradient>
			</defs>

			<path fill="none" stroke="#7a8395" strokeMiterlimit="10" d="M362.5 342.69h-133" />
			<path fill="none" stroke="#7a8395" strokeMiterlimit="10" d="M229.03 214.803V44.724" />
			<path fill="none" stroke="#7a8395" strokeMiterlimit="10" d="M229.03 392.544V262.15" />
			<path fill="none" stroke="#7a8395" strokeMiterlimit="10" d="M408.5 299.843v-51.024" />
			<path fill="none" stroke="#7a8395" strokeMiterlimit="10" d="M202 219.5v-99H88.614" />
			<path fill="none" stroke="#7a8395" strokeMiterlimit="10" d="M49 160.945V280" />
			<path fill="none" stroke="#7a8395" strokeMiterlimit="10" d="M389.228 120.5H256v99" />

			<circle r="3.27" fill="#fff" className={styles.circle1} />
			<circle r="3.27" fill="#fff" className={styles.circle2} />
			<circle r="3.27" fill="#fff" className={styles.circle3} />
			<circle r="3.27" fill="#fff" className={styles.circle4} />
			<circle r="3.27" fill="#fff" className={styles.circle5} />
			<circle r="3.27" fill="#fff" className={styles.circle6} />
			<circle r="3.27" fill="#fff" className={styles.circle7} />

			<circle cx="49" cy="121" r="49" fill="#162745" />
			<circle cx="49" cy="121" r="48.5" fill="none" stroke="#60ba97" />
			<circle cx="229.03" cy="432.26" r="49" fill="#162745" />
			<circle cx="229.03" cy="432.26" r="48.5" fill="none" stroke="#60ba97" />
			<circle cx="408.5" cy="121" r="27" fill="#162745" />
			<circle cx="408.5" cy="121" r="26.5" fill="none" stroke="#60ba97" />
			<circle cx="229" cy="27" r="27" fill="#162745" />
			<circle cx="229" cy="27" r="26.5" fill="none" stroke="#60ba97" />
			<circle cx="49" cy="298.26" r="27" fill="#162745" />
			<circle cx="49" cy="298.26" r="26.5" fill="none" stroke="#60ba97" />
			<circle cx="408.5" cy="342.69" r="52.81" fill="#162745" />
			<circle cx="408.5" cy="342.69" r="52.81" fill="none" stroke="#60ba97" />
			<circle cx="408.5" cy="231.84" r="27" fill="#162745" />
			<circle cx="408.5" cy="231.84" r="26.5" fill="none" stroke="#60ba97" />

			<g fill="url(#gradient)">
				<path d="M229 257.06a55 55 0 0144.79 23.16l-10.4 6c-14.275-18.979-41.232-22.793-60.212-8.518a42.993 42.993 0 00-8.518 8.518l-10.4-6A54.92 54.92 0 01229 257.06z" />
				<path d="M229 233.36a78.671 78.671 0 0165.38 35l-10.38 6c-20.804-30.387-62.303-38.156-92.69-17.351A66.683 66.683 0 00174 274.3l-10.37-6A78.64 78.64 0 01229 233.36z" />
				<path d="M229 209.07a103 103 0 0186.42 47.1l-10.35 6c-27.53-42.014-83.907-53.755-125.921-26.225A90.949 90.949 0 00152.97 262.1l-10.35-6A103 103 0 01229 209.07z" />
			</g>

			<path
				fill="#60ba97"
				d="M234.52 19.37a7.879 7.879 0 00-13.39 5.52 7.78 7.78 0 003 6.3 3.892 3.892 0 011.46 2.92v2.36a1.83 1.83 0 001.647 1.996l.043.004h3.38a1.73 1.73 0 001.69-1.69v-2.59a4.12 4.12 0 011.57-3.15 7.54 7.54 0 002.93-6.07 7.996 7.996 0 00-2.33-5.6zm-3.83 18h-3.37c-.57 0-.57-.67-.57-.9v-1.35h4.5v1.69a.52.52 0 01-.59.56h.03zm2.59-7.2a5.491 5.491 0 00-2 3.83h-4.52a4.898 4.898 0 00-1.91-3.71 6.618 6.618 0 01-2.59-5.4 6.91 6.91 0 016.75-6.64 6.63 6.63 0 014.73 1.91 6.73 6.73 0 012 4.84 6.562 6.562 0 01-2.46 5.17zM229 14.87a.53.53 0 00.57-.56v-2.25a.57.57 0 00-1.13 0v2.25a.53.53 0 00.56.56zM237.66 15.55l-1.58 1.57a.56.56 0 000 .79.695.695 0 00.45.11c.118-.019.233-.057.34-.11l1.57-1.57a.54.54 0 00.027-.763l-.027-.027a.53.53 0 00-.78 0zM241.94 24.44h-2.28a.56.56 0 000 1.12h2.25a.56.56 0 000-1.12h.03zM236.88 32.2a.54.54 0 00-.763-.027l-.027.027a.56.56 0 000 .79l1.58 1.57a.67.67 0 00.45.11c.22 0 .33 0 .33-.11a.54.54 0 00.027-.763l-.027-.027-1.57-1.57zM221.02 32.2l-1.58 1.57a.56.56 0 000 .79.695.695 0 00.45.11.42.42 0 00.34-.11l1.57-1.57a.54.54 0 00.027-.763l-.027-.027a.53.53 0 00-.78 0zM218.32 24.44h-2.25a.56.56 0 00-.004 1.12h2.253a.56.56 0 00.004-1.12h-.003zM221.02 17.91a.67.67 0 00.45.11c.11 0 .22-.11.33-.22a.54.54 0 00.027-.763l-.027-.027-1.57-1.57a.56.56 0 10-.79.779l1.58 1.691z"
			/>
			<path
				fill="#60ba97"
				d="M229 19.94a.56.56 0 00-.004 1.12h.003a4 4 0 013.94 3.94.57.57 0 001.13 0A5 5 0 00229 19.94zM73.26 132.43h-2.6v-26.55a3 3 0 00-3-3H30.13a3 3 0 00-3 3v26.55h-2.38a.76.76 0 00-.75.75v1.64a4.31 4.31 0 004.3 4.3h28a.74.74 0 00.74-.74.75.75 0 00-.74-.75h-28a2.81 2.81 0 01-2.81-2.81v-.9h17.5a2.65 2.65 0 002.65 2.6h6.73a2.66 2.66 0 002.65-2.6h17.5v.9a2.81 2.81 0 01-2.81 2.81h-10a.76.76 0 00-.75.75c.005.41.34.74.75.74h9.95a4.3 4.3 0 004.3-4.3v-1.64a.75.75 0 00-.7-.75zm-20.89 2.6h-6.71a1.17 1.17 0 01-1.17-1.11h9.06a1.16 1.16 0 01-1.18 1.11zm16.79-2.6h-40.5v-26.55a1.52 1.52 0 011.52-1.51h37.48a1.52 1.52 0 011.52 1.51l-.02 26.55z"
			/>
			<path
				fill="#60ba97"
				d="M67.13 105.63H38.36a.75.75 0 00-.74.75c0 .409.331.74.74.74h28v22.51h-35v-22.49h3.5a.75.75 0 00.75-.74.76.76 0 00-.75-.75h-4.2a.75.75 0 00-.74.75v24c0 .409.331.74.74.74h36.49a.74.74 0 00.74-.74v-24a.75.75 0 00-.73-.77h-.03zM59.5 287.01a2.51 2.51 0 00-2.5 2.49 2.458 2.458 0 00.71 1.74l-4.57 9.14h-.3a2.464 2.464 0 00-.94.18l-3.55-4c.209-.365.32-.779.32-1.2a2.5 2.5 0 10-4.39 1.621l-4.33 7.59a2.35 2.35 0 00-.45-.05 2.53 2.53 0 101.89.88l4.33-7.59c.467.089.95.041 1.39-.14l3.54 4a2.5 2.5 0 104.69 1.21 2.458 2.458 0 00-.71-1.74L59.2 292h.3a2.5 2.5 0 100-5v.01zm-20 20.84a.84.84 0 01-.83-.84.83.83 0 01.83-.83c.46 0 .835.37.84.83a.849.849 0 01-.84.84zm6.67-11.67a.83.83 0 11.001-1.661.83.83 0 01-.001 1.661zm6.67 7.5a.84.84 0 01-.84-.83.849.849 0 01.84-.84c.46.005.83.38.83.84a.83.83 0 01-.83.83zm6.66-13.33a.84.84 0 01-.83-.84.83.83 0 01.83-.83c.46 0 .835.37.84.83a.849.849 0 01-.84.84zM418.26 318.19h-21.05a3.59 3.59 0 00-3.59 3.59v41.85a3.59 3.59 0 003.59 3.59h21.05a3.59 3.59 0 003.59-3.59v-41.85a3.59 3.59 0 00-3.59-3.59zm2.16 38.95h-20.34a.72.72 0 100 1.44h20.34v5a2.16 2.16 0 01-2.16 2.15h-21.05a2.16 2.16 0 01-2.16-2.15v-5h2.16a.72.72 0 100-1.44h-2.16v-31.77h25.37v31.77zm0-33.21h-25.37v-2.15a2.16 2.16 0 012.16-2.15h21.05a2.16 2.16 0 012.16 2.15v2.15z"
			/>
			<path fill="#60ba97" d="M410.66 321.06h-2.88a.72.72 0 100 1.44h2.88a.72.72 0 100-1.44zM407.73 360.01a2.16 2.16 0 102.16 2.16 2.16 2.16 0 00-2.16-2.16zm0 2.87a.72.72 0 11.72-.72v.01a.71.71 0 01-.71.71h-.01z" />
			<circle cx="404.86" cy="321.78" r=".72" fill="#60ba97" />
			<path
				fill="#60ba97"
				d="M421 219.73V219.58h-.3l-18.92 8.41a.43.43 0 00-.222.566l.002.004c.1.22.356.322.58.23l16.34-7.27-15.1 13.43-7.55-3.36 4.56-2a.43.43 0 00.222-.566l-.002-.004a.44.44 0 00-.578-.231l-.002.001-5.46 2.43a.44.44 0 000 .8l8.49 3.77.93 7v.18a.297.297 0 00.09.09H404.56l3.77-3.71 6.29 5.19a.48.48 0 00.28.1.366.366 0 00.14 0 .43.43 0 00.29-.31l5.8-24.19a.122.122 0 000-.06l-.13-.35zm-16.65 21.19l-.74-5.52 13.31-11.83-10.73 13.32-1.84 4.03zm1.14-.4l1.21-2.65.81.66-2.02 1.99zm9 2.64l-7.35-6.06 12.52-15.56-5.17 21.62zM250.36 414.24h-2.56a.74.74 0 000 1.47h2.56a2.19 2.19 0 012.2 2.18v3.38h-47.07v-3.37a2.2 2.2 0 012.2-2.19h36.89a.74.74 0 100-1.47h-36.92a3.67 3.67 0 00-3.66 3.66v8.08a.729.729 0 001.46 0v-3.24h47.1v18.89a.74.74 0 001.47 0V417.9a3.68 3.68 0 00-3.67-3.66zM253.29 443.98a.73.73 0 00-.73.73v1.92c0 1.21-.98 2.19-2.19 2.19h-42.71a2.2 2.2 0 01-2.2-2.19v-17.5a.729.729 0 00-1.46 0v17.5a3.67 3.67 0 003.66 3.66h42.7a3.68 3.68 0 003.67-3.66v-1.91a.74.74 0 00-.74-.74z"
			/>
			<path
				fill="#60ba97"
				d="M214.66 430.16a.73.73 0 00-1 0l-4.4 4.4a.757.757 0 00-.21.52.742.742 0 00.21.51l4.35 4.35a.73.73 0 001-1l-3.82-3.82 3.88-3.89a.72.72 0 000-1l-.01-.07zM224.47 439.94a.732.732 0 00.52.21.768.768 0 00.52-.21l4.34-4.35a.688.688 0 00.21-.51.722.722 0 00-.21-.52l-4.4-4.4a.73.73 0 10-1 1l3.88 3.89-3.82 3.82a.73.73 0 000 1l-.04.07zM216.92 442.63a.731.731 0 00.68-.47l5.2-13.71a.73.73 0 00-1.36-.52l-5.21 13.7a.72.72 0 00.69 1zM207.25 418.53a2.2 2.2 0 102.2-2.2 2.19 2.19 0 00-2.2 2.18v.02zm2.93 0a.74.74 0 11-.74-.74h.01a.73.73 0 01.73.74zM212.93 418.53a2.2 2.2 0 102.2-2.2 2.19 2.19 0 00-2.2 2.18v.02zm2.93 0a.74.74 0 11-.74-.74h.01a.73.73 0 01.73.74zM218.66 418.53a2.2 2.2 0 102.2-2.2 2.19 2.19 0 00-2.2 2.18v.02zm2.93 0a.74.74 0 11-.74-.74h.01a.73.73 0 01.68.74h.05zM236.79 430.93h4.59a2.2 2.2 0 000-4.4h-4.59a2.2 2.2 0 000 4.4zm0-2.93h4.59a.73.73 0 010 1.46h-4.59a.73.73 0 010-1.46zM236.79 436.79h12.11a2.2 2.2 0 000-4.4h-12.11a2.2 2.2 0 000 4.4zm0-2.93h12.11a.73.73 0 010 1.46h-12.11a.73.73 0 010-1.46zM236.79 442.63h8.51a2.2 2.2 0 000-4.4h-8.51a2.2 2.2 0 000 4.4zm0-2.93h8.51a.73.73 0 010 1.46h-8.51a.73.73 0 010-1.46zM415.13 119.13v-4a6.651 6.651 0 00-2-4.77 6.411 6.411 0 00-4.56-1.9h-.09a6.65 6.65 0 00-6.61 6.67v4a2.71 2.71 0 00-2.39 2.72v8.89a2.74 2.74 0 002.72 2.76h12.59a2.74 2.74 0 002.72-2.76v-8.89a2.73 2.73 0 00-2.38-2.72zm-12.2-4a5.61 5.61 0 015.56-5.63 5.57 5.57 0 013.89 1.6 5.648 5.648 0 011.66 4v4h-1.17v-4a4.42 4.42 0 00-1.32-3.2 4.259 4.259 0 00-3-1.27 4.44 4.44 0 00-4.41 4.47v4h-1.17v-4l-.04.03zm8.91 0v4h-6.68v-4a3.38 3.38 0 013.35-3.4 3.251 3.251 0 012.3.95 3.469 3.469 0 011.03 2.49v-.04zm4.66 15.59a1.68 1.68 0 01-1.68 1.68h-12.59a1.68 1.68 0 01-1.68-1.68v-8.88c0-.928.752-1.68 1.68-1.68h12.59c.928 0 1.68.752 1.68 1.68v8.88z"
			/>
			<path fill="#60ba97" d="M410.33 125.99a1.91 1.91 0 10-2.36 2.36v1.49a.53.53 0 001.06 0v-1.49a1.9 1.9 0 001.303-2.35l-.003-.01zm-1.83 1.38a.86.86 0 11.85-.87v.01a.851.851 0 01-.84.86h-.01z" />
		</svg>
	);
};

export default LoraIllustration;
