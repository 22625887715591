import React, { FC } from "react";
import { Helmet } from "react-helmet";
import LoraText from "../components/LoraText";
import LoraHead from "../components/LoraHead";
import LoraButton from "../components/LoraButton";
import styles from "../styles/pages/AboutUsPage.module.scss";
import { PAGE_NAME } from "../layout/config";

const hostname = window.location.protocol + "//" + window.location.hostname;

const AboutUsPage: FC = () => {
	return (
		<>
			<Helmet>
				<title>O nás | {PAGE_NAME}</title>
				<meta
					name="description"
					content="Vytvořili jsme software pro ovládání a kontrolu koncových zařízení a senzorů, díky němuž je možné individuální ovládání jednotlivých svítidel veřejného osvětlení, jejich intenzity a diagnostiky a to odkudkoliv."
				/>
				<meta property="og:title" content="O nás | {PAGE_NAME}" />
				<meta
					property="og:description"
					content="Vytvořili jsme software pro ovládání a kontrolu koncových zařízení a senzorů, díky němuž je možné individuální ovládání jednotlivých svítidel veřejného osvětlení, jejich intenzity a diagnostiky a to odkudkoliv."
				/>
				<link rel="canonical" href={hostname + "/o-nas"} />
				<link rel="alternate" hrefLang="cs-cs" href={hostname + "/o-nas"} />
			</Helmet>

			<main className={styles.aboutUs}>
				<div className="bg-primary padding-top-60 padding-bottom-60">
					<div className="container">
						<LoraHead loraElement="h1" loraColor="secondary">
							Několik informací o nás
						</LoraHead>
						<LoraText loraStyle="longtext">
							Vytvořili jsme software pro ovládání a kontrolu koncových zařízení a senzorů, díky němuž je možné individuální ovládání jednotlivých svítidel veřejného osvětlení, jejich intenzity a diagnostiky a to odkudkoliv.
						</LoraText>
						<LoraText loraStyle="longtext">
							Představte si, že máte kontrolu nad ovládáním osvětlení celé obce, města nebo vaší společnosti z pohodlí domova nebo na i cestách. V reálném čase tak můžete nastavit a řídit časové plány, intenzitu a získávat diagnostiku
							osvětlení.
						</LoraText>
						<LoraText loraStyle="longtext">
							Výhodou tohoto řešení je mimo jiné energetická úspora spotřebovaných energií díky snížení výkonu osvětlení ve vedlejších ulicích či méně frekventovaných míst obcí. Světla v jednotlivých ulicích či částech měst a obcí mohou
							být individuálně nastavena a ovládána na dálku dle zvoleného časového plánu. Změnou intenzity tak dochází ke snížení odběru elektrické energie a snížení energetické náročnosti.
						</LoraText>
						<LoraText loraStyle="longtext" loraCustomClass="margin-bottom-60">
							Náš software dále umožňuje ovládání zařízení třetích stran a připravujeme také integraci třetích stran s možností připojení k tomuto softwaru.
						</LoraText>
						<LoraHead loraElement="h2" loraColor="secondary" loraStyle="h3">
							Díky neustálému vývoji a zdokonalování tento software nabízí stále širší využití.
						</LoraHead>
						<LoraText loraStyle="longtext">
							Cílem naší společnosti je rozšíření softwaru například o paspartizaci sítí, doplnění ovládání chytrých rozvaděčů, diagnostiku a ovládání jistících prvků, vzdálenou kontrolu nad zásuvkami což umožní např. zapínání a
							vypínání světelných dekorací nebo elektrospotřebičů.
						</LoraText>
						<LoraText loraStyle="longtext">Software také dokážeme přizpůsobit požadavkům a potřebám každého zákazníka. Naše zkušenosti rádi použijeme při práci i na vašem projektu.</LoraText>
						<LoraText loraCustomClass="text-center">
							<LoraButton loraVariant="text-secondary" path="/kontakt">
								V případě zájmu o individuální konzultaci nás neváhejte kontaktovat.
							</LoraButton>
						</LoraText>
					</div>
				</div>

				<div className="bg-primary-light padding-top-60 padding-bottom-60">
					<div className="container">
						<LoraHead loraElement="h2" loraColor="primary">
							Využíváme komunikační protokol LoRaWAN™
						</LoraHead>
						<LoraText loraStyle="longtext">
							LoRaWAN je síť, na níž pracuje LoRa®, a může být využívána IoT zařízeními v obtížně připojitelných oblastech. Tato síť byla vytvořena za účelem připojení malých, cenově dostupných, bateriově poháněných, bezdrátových
							zařízení, které mají sloužit převážně v domácnostech a v průmyslu, jako čidla a senzory nejrůznějšího druhu.
						</LoraText>
						<LoraText loraStyle="longtext">
							LoRaWAN™ poskytuje mechanismus řízení přístupu ke sdílenému médiu (MAC), umožňuje tak koncovým zařízením komunikovat s bránou za použití LoRa® modulace. Jeho využití je hlavně v senzorových sítích, kde senzory komunikují
							se serverem s nízkou datovou rychlostí a relativně vysokými latencemi (jedna zpráva v intervalu od několika minut až dní). Zatímco je LoRaWAN™ modulace proprietární, LoRaWAN™ protokol je otevřený standard, který vyvíjí
							LoRa Alliance.
						</LoraText>
						<LoraText loraStyle="longtext">
							Název LoRa® je odvozen ze slov Long Range. LoRa® je fyzická vrstva nebo také radiová modulace, využívaná pro vytvoření komunikačního spojení na velkou vzdálenost (až 40 km), je navržená a patentovaná firmou Semtech
							Corporation, která se stará i o výrobu čipů. LoRa® modulace je derivátem CSS (Chirp Spread Spectrum) modulace. Tato modulace využívá techniku rozprostřeného spektra signálu, který je odolný vůči rušivému úzkopásmovému
							šumu, pomocí širokopásmových lineárních kmitočtově modulovaných kmitů (chirpů). LoRa® modulace si zachovává vysokou efektivitu i při nízkém vysílacím výkonu, ale zároveň výrazně zvyšuje dosažitelnou komunikační vzdálenost.
						</LoraText>
					</div>
				</div>
			</main>
		</>
	);
};

export default AboutUsPage;
