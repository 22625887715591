import React, { FC } from "react";
import { Helmet } from "react-helmet";
import LoraHead from "../components/LoraHead";
import LoraButton from "../components/LoraButton";
import styles from "../styles/pages/PartnersPage.module.scss";
import { PAGE_NAME } from "../layout/config";

const hostname = window.location.protocol + "//" + window.location.hostname;

const ForPartnersPage: FC = () => {
	return (
		<>
			<Helmet>
				<title>Pro partnery | {PAGE_NAME}</title>
				<meta name="description" content="Přístupy pro partnery" />
				<meta property="og:title" content="Přístupy pro partnery | {PAGE_NAME}" />
				<meta property="og:description" content="Přístupy pro partnery" />
				<link rel="canonical" href={hostname + "/pro-partnery"} />
				<link rel="alternate" hrefLang="cs-cs" href={hostname + "/pro-partnery"} />
			</Helmet>

			<main className={styles.partners}>
				<div className="bg-primary padding-top-60 padding-bottom-60">
					<div className="container">
						<LoraHead loraElement="h1" loraColor="secondary" loraCustomClass="margin-bottom-60">
							Přístupy pro partnery
						</LoraHead>
						<div className="grid margin-bottom-60">
							{/*<div className="col col-12 col-6@lg">
								<div>
									<LoraHead loraElement="h3" loraColor="white" loraStyle="h5" loraCustomClass="margin-bottom-20">
										loraosvetleni.cz
									</LoraHead>
									<LoraButton loraVariant="primary" path="//lora.loraosvetleni.cz/login/" target="_blank" loraCustomClass="margin-bottom-0@md">
										{"loraosvetleni.cz"}
									</LoraButton>
								</div>
							</div>*/}
							<div className="col col-12 col-6@lg">
								<div>
									<LoraHead loraElement="h3" loraColor="white" loraStyle="h5" loraCustomClass="margin-bottom-20">
										rohatec
									</LoraHead>
									<LoraButton loraVariant="primary" path="//rohatec.loraosvetleni.eu/login" target="_blank" loraCustomClass="margin-bottom-0@md">
										{"rohatec.loraosvetleni.eu"}
									</LoraButton>
								</div>
							</div>
							<div className="col col-12 col-6@lg">
								<div>
									<LoraHead loraElement="h3" loraColor="white" loraStyle="h5" loraCustomClass="margin-bottom-20">
										slavičín
									</LoraHead>
									<LoraButton loraVariant="primary" path="//slavicin.loraosvetleni.eu/login" target="_blank" loraCustomClass="margin-bottom-0@md">
										{"slavicin.loraosvetleni.eu"}
									</LoraButton>
								</div>
							</div>
							<div className="col col-12 col-6@lg">
								<div>
									<LoraHead loraElement="h3" loraColor="white" loraStyle="h5" loraCustomClass="margin-bottom-20">
										kemp-merkur
									</LoraHead>
									<LoraButton loraVariant="primary" path="//kemp-merkur.loraosvetleni.eu/login" target="_blank" loraCustomClass="margin-bottom-0@md">
										{"kemp-merkur.loraosvetleni.eu"}
									</LoraButton>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

export default ForPartnersPage;
