import React, { FC, useState } from "react";
import LoraLogo from "../components/LoraLogo";
import { NavLink } from "react-router-dom";
import styles from "../styles/layout/LoraMenu.module.scss";
import clsx from "clsx";

type MenuType = "header" | "footer" | "responsive";

interface MenuProps {
	type?: MenuType;
}

const LoraMenu: FC<MenuProps> = (props) => {
	const menuList = [
		{
			text: "Řešení",
			link: "/reseni",
		},
		{
			text: "Produkty",
			link: "/produkty",
		},
		{
			text: "O nás",
			link: "/o-nas",
		},
		{
			text: "Reference",
			link: "/reference",
		},
		{
			text: "Pro partnery",
			link: "/pro-partnery",
		},
		{
			text: "Partneři",
			link: "/partneri",
		},
		{
			text: "Kontakt",
			link: "/kontakt",
		},
	];

	const [open, setOpen] = useState(false);
	const handleOpen = () => {
		setOpen(true);
		document.body.classList.add(styles.menuResponsiveOpen);
	};
	const handleClose = () => {
		setOpen(false);
		document.body.classList.remove(styles.menuResponsiveOpen);
	};

	const useClasses = () => {
		switch (props.type) {
			case "header":
				return { menuNav: styles.menuHeaderNav, menuLink: styles.menuHeaderLink, menuLinkActive: styles.menuHeaderLinkActive };
			case "footer":
				return { menuNav: styles.menuFooterNav, menuLink: styles.menuFooterLink, menuLinkActive: styles.menuFooterLinkActive };
			case "responsive":
				return { menuNav: styles.menuResponsiveNav, menuLink: styles.menuResponsiveLink, menuLinkActive: styles.menuResponsiveLinkActive };
			default:
				return { menuNav: styles.menuDefaultNav, menuLink: styles.menuDefaultLink, menuLinkActive: styles.menuDefaultLinkActive };
		}
	};

	let menuNavClassName = useClasses()?.menuNav;
	let menuLinkClassName = useClasses()?.menuLink;
	let menuLinkActiveClassName = useClasses()?.menuLinkActive;

	return (
		<>
			{props.type === "responsive" ? (
				<>
					<div onClick={open ? handleClose : handleOpen} className={clsx({ [styles.menuResponsiveButton]: true, [styles.menuResponsiveButtonOpen]: open })}>
						<div>
							<div></div>
						</div>
					</div>
					<nav className={clsx(styles.menuResponsiveOverlay, { [styles.menuResponsiveOverlayOpen]: open })}>
						<NavLink to="/" className={styles.menuResponsiveOverlayLogo} onClick={handleClose}>
							<LoraLogo />
						</NavLink>
						<ul className={styles.menuResponsiveOverlayInner}>
							{menuList.map((item) => (
								<li key={item.text} onClick={handleClose}>
									<NavLink key={item.text} to={item.link} className={({ isActive }) => clsx(menuLinkClassName, { [menuLinkActiveClassName]: isActive })}>
										{item.text}
									</NavLink>
								</li>
							))}
						</ul>
					</nav>
				</>
			) : (
				<>
					<nav className={menuNavClassName}>
						{menuList.map((item) => (
							<NavLink key={item.text} to={item.link} className={({ isActive }) => clsx({ [menuLinkClassName]: true, [menuLinkActiveClassName]: isActive })}>
								{item.text}
							</NavLink>
						))}
					</nav>
				</>
			)}
		</>
	);
};

export default LoraMenu;
