import React, { FC } from "react";
import { Helmet } from "react-helmet";
import LoraText from "../components/LoraText";
import LoraHead from "../components/LoraHead";
import LoraButton from "../components/LoraButton";
import styles from "../styles/pages/SolutionsPage.module.scss";
import { PAGE_NAME } from "../layout/config";

const hostname = window.location.protocol + "//" + window.location.hostname;

const SolutionsPage: FC = () => {
	return (
		<>
			<Helmet>
				<title>Řešení | {PAGE_NAME}</title>
				<meta
					name="description"
					content="Vytvořili jsme software pro ovládání a kontrolu koncových zařízení a senzorů, díky němuž je možné individuální ovládání jednotlivých svítidel veřejného osvětlení, jejich intenzity a diagnostiky. Osvětlení celé obce,
							města nebo Vaší společnosti jednoduše a v reálném čase nastavíte a řídíte z pohodlí domova nebo na i cestách. Dle potřeb nastavíte časové plány, intenzitu a diagnostiku osvětlení."
				/>
				<meta property="og:title" content="Řešení | {PAGE_NAME}" />
				<meta
					property="og:description"
					content="Vytvořili jsme software pro ovládání a kontrolu koncových zařízení a senzorů, díky němuž je možné individuální ovládání jednotlivých svítidel veřejného osvětlení, jejich intenzity a diagnostiky. Osvětlení celé obce,
							města nebo Vaší společnosti jednoduše a v reálném čase nastavíte a řídíte z pohodlí domova nebo na i cestách. Dle potřeb nastavíte časové plány, intenzitu a diagnostiku osvětlení."
				/>
				<link rel="canonical" href={hostname + "/reseni"} />
				<link rel="alternate" hrefLang="cs-cs" href={hostname + "/reseni"} />
			</Helmet>

			<main className={styles.solutions}>
				<div className="bg-primary padding-top-60 padding-bottom-60">
					<div className="container">
						<LoraHead loraElement="h1" loraColor="secondary">
							O co vlastně jde
						</LoraHead>
						<LoraText loraStyle="longtext">
							Internet věcí (anglicky Internet of Things, zkratka IoT) popisuje síť fyzických objektů „věcí“, které jsou vybaveny senzory, softwarem a dalšími technologiemi za účelem propojení a výměny dat s jinými zařízeními a systémy
							přes internet. Jsou snadno ovladatelné pomocí mobilního telefonu, notebooku, centrální jednotky nebo třeba chytrých hodinek. Slouží zejména ke kontrole, měření a řízení jiných zařízení nebo procesů.
						</LoraText>
						<LoraText loraStyle="longtext" loraCustomClass="margin-bottom-60">
							Díky nim dokážete běžné činnosti jako např. nastavení osvětlení a jeho intenzity efektivně zautomatizovat. Stav svítidel jednoduše ověříte pomocí vzdálené diagnostiky na vašem chytrém telefonu a ušetříte tak čas, peníze,
							ale hlavně vám odpadnou starosti. O průběhu a výsledcích jste vždy informován, ať se nacházíte kdekoli.
						</LoraText>
						<LoraHead loraElement="h2" loraColor="secondary">
							Naše specializace
						</LoraHead>
						<LoraText loraStyle="longtext">
							Vytvořili jsme <b>software</b> pro ovládání a kontrolu koncových zařízení a senzorů, díky němuž je možné individuální ovládání jednotlivých svítidel veřejného osvětlení, jejich intenzity a diagnostiky. Osvětlení celé obce,
							města nebo vaší společnosti jednoduše a v reálném čase nastavíte a řídíte z pohodlí domova nebo na i cestách. Dle potřeb nastavíte časové plány, intenzitu a diagnostiku osvětlení.
						</LoraText>
					</div>
				</div>

				<div className="bg-white padding-top-60 padding-bottom-60">
					<div className="container">
						<LoraHead loraElement="h2" loraColor="primary">
							S jakými zařízeními pracujeme?
						</LoraHead>
						<div className="grid">
							<div className="col col-12 col-6@lg">
								<div className="padding-30 padding-40@lg shadow-1">
									<LoraHead loraElement="h3" loraColor="secondary" loraStyle="h5" loraCustomClass="margin-bottom-0">
										Řízené veřejné osvětlení
									</LoraHead>
								</div>
							</div>
							<div className="col col-12 col-6@lg">
								<div className="padding-30 padding-40@lg shadow-1">
									<LoraHead loraElement="h3" loraColor="secondary" loraStyle="h5" loraCustomClass="margin-bottom-0">
										RS-485 sonda k měření teploty a vlhkosti
									</LoraHead>
								</div>
							</div>
							<div className="col col-12 col-6@lg">
								<div className="padding-30 padding-40@lg shadow-1">
									<LoraHead loraElement="h3" loraColor="secondary" loraStyle="h5" loraCustomClass="margin-bottom-0">
										Dálkový odečet Elcor ELGAS
									</LoraHead>
								</div>
							</div>
							<div className="col col-12 col-6@lg">
								<div className="padding-30 padding-40@lg shadow-1">
									<LoraHead loraElement="h3" loraColor="secondary" loraStyle="h5" loraCustomClass="margin-bottom-0">
										Dálkové odečty vodoměrů a ITN
									</LoraHead>
								</div>
							</div>
						</div>
						<LoraText loraCustomClass="margin-top-60 margin-bottom-0 text-center">
							<LoraButton loraVariant="secondary" path="/o-nas">
								Více o nás
							</LoraButton>
						</LoraText>
					</div>
				</div>
			</main>
		</>
	);
};

export default SolutionsPage;
