import React, { FC } from "react";
import LoraText from "../components/LoraText";
import styles from "../styles/layout/LoraFooter.module.scss";
import LoraLogo from "../components/LoraLogo";
import { PAGE_NAME } from "./config";

const LoraFooter: FC = (props) => {
	return (
		<>
			<footer className={styles.footer}>
				<div className="container">
					<div className="grid">
						<div className="col col-12 col-6@lg">
							<LoraText>
								<LoraLogo />
							</LoraText>
							<LoraText loraCustomClass="margin-bottom-0">Společnost je zapsána u Krajského soudu v Brně pod spisovou značkou C 112532/KSBR.</LoraText>
						</div>
						<div className="col col-12 col-6@lg">
							<LoraText>
								{PAGE_NAME},
								<br />
								Veslařská 153/136, 637 00 Brno,
								<br />
								IČO: 08223131, DIČ: CZ08223131
							</LoraText>
							{/*<LoraText loraCustomClass="margin-bottom-0">
								Partneři společnosti:
								<br />
								Přemysl Tetur, František Koryčan, Radek Fryšták, Petr Šmarda.
							</LoraText>*/}
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};

export default LoraFooter;
