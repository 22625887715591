import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./styles/base/normalize.scss";
import "./styles/base/global.scss";
import "./styles/base/container.scss";
import "./styles/base/grid.scss";
import "./styles/base/utility.scss";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>
);
