import React, { FC } from "react";
import { Helmet } from "react-helmet";
import LoraIllustration from "../components/LoraIllustration";
import LoraPreHead from "../components/LoraPreHead";
import LoraHead from "../components/LoraHead";
import LoraText from "../components/LoraText";
import LoraButton from "../components/LoraButton";
import styles from "../styles/pages/HomePage.module.scss";
import { PAGE_NAME } from "../layout/config";

const hostname = window.location.protocol + "//" + window.location.hostname;

const HomePage: FC = () => {
	return (
		<>
			<Helmet>
				<title>{PAGE_NAME} - Spolehlivý IOT systém pro Vaši firmu</title>
				<meta name="description" content="Spolehlivý IOT systém pro Vaši firmu" />
				<meta property="og:title" content="{PAGE_NAME} - Spolehlivý IOT systém pro Vaši firmu" />
				<meta property="og:description" content="Spolehlivý IOT systém pro Vaši firmu" />
				<link rel="canonical" href={hostname + "/"} />
				<link rel="alternate" hrefLang="cs-cs" href={hostname + "/"} />
			</Helmet>

			<main className={styles.home}>
				<div className="bg-primary padding-top-60 padding-top-150@lg padding-bottom-60 padding-bottom-150@lg">
					<div className="container">
						<div className="grid">
							<div className="col col-12 col-6@lg">
								<LoraIllustration />
							</div>
							<div className="col col-middle col-12 col-6@lg">
								<LoraPreHead>Představujeme vám</LoraPreHead>
								<LoraHead loraElement="h1" loraColor="white">
									Spolehlivý IOT systém
									<br />
									pro Vaši firmu
								</LoraHead>
								<LoraText loraCustomClass="margin-bottom-60" loraStyle="longtext">
									Jsme inovativní česká společnost zabývající se vývojem softwaru IoT v oblasti veřejného osvětlení a senzoriky.
								</LoraText>
								<LoraText loraCustomClass="text-center text-left@lg">
									<LoraButton loraVariant="primary" path="/o-nas">
										Zjistit více
									</LoraButton>
								</LoraText>
							</div>
						</div>
					</div>
				</div>

				<div className="bg-primary-light padding-top-60 padding-top-150@lg padding-bottom-60 padding-bottom-150@lg">
					<div className="container">
						<LoraPreHead>Neváhejte se nám ozvat</LoraPreHead>
						<LoraHead loraElement="h2" loraColor="primary">
							Máte zájem o spolupráci?
						</LoraHead>
						<LoraText loraCustomClass="text-center text-left@lg">
							<LoraButton loraVariant="primary" path="/kontakt">
								Kontaktujte nás
							</LoraButton>
						</LoraText>
					</div>
				</div>
			</main>
		</>
	);
};

export default HomePage;
