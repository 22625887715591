import React, { FC } from "react";
import { Helmet } from "react-helmet";
import LoraText from "../components/LoraText";
import LoraHead from "../components/LoraHead";
import styles from "../styles/pages/ContactPage.module.scss";
import { PAGE_NAME } from "../layout/config";

const hostname = window.location.protocol + "//" + window.location.hostname;

const ContactPage: FC = () => {
	return (
		<>
			<Helmet>
				<title>Kontakt | {PAGE_NAME}</title>
				<meta name="description" content="Kontaktujte nás" />
				<meta property="og:title" content="Kontakt | {PAGE_NAME}" />
				<meta property="og:description" content="Kontaktujte nás" />
				<link rel="canonical" href={hostname + "/kontakt"} />
				<link rel="alternate" hrefLang="cs-cs" href={hostname + "/kontakt"} />
			</Helmet>

			<main className={styles.contact}>
				<div className="bg-primary padding-top-60 padding-bottom-60">
					<div className="container">
						<LoraHead loraElement="h1" loraColor="secondary">
							Kontaktujte nás
						</LoraHead>
						<LoraText loraCustomClass="margin-bottom-60">
							{PAGE_NAME},
							<br />
							Veslařská 153/136, 637 00 Brno,
							<br />
							IČO: 08223131, DIČ: CZ08223131
						</LoraText>
						{/*<LoraHead loraElement="h2" loraColor="secondary" loraStyle="h3">
							Partneři společnosti
						</LoraHead>*/}
						<div className="grid margin-bottom-60">
							<div className="col col-12 col-6@lg">
								<div>
									<LoraHead loraElement="h3" loraColor="white" loraStyle="h5" loraCustomClass="margin-bottom-20">
										Přemysl Tetur
									</LoraHead>
									<LoraText loraCustomClass="margin-bottom-0@md">
										Jednatel společnosti
										<br />
										<a href="tel:+420776300126">+420 776 300 126</a>
										<br />
										<a href="mailto:tetur@loraosvetleni.cz">tetur@loraosvetleni.cz</a>
									</LoraText>
								</div>
							</div>
							<div className="col col-12 col-6@lg">
								<div>
									<LoraHead loraElement="h3" loraColor="white" loraStyle="h5" loraCustomClass="margin-bottom-20">
										Petr Šmarda
									</LoraHead>
									<LoraText loraCustomClass="margin-bottom-0@md">
										Obchodní ředitel
										<br />
										<a href="tel:+420739995554">+420 739 995 554</a>
										<br />
										<a href="mailto:smarda@loraosvetleni.cz">smarda@loraosvetleni.cz</a>
									</LoraText>
								</div>
							</div>
							<div className="col col-12 col-6@lg">
								<div>
									<LoraHead loraElement="h3" loraColor="white" loraStyle="h5" loraCustomClass="margin-bottom-20">
										František Koryčan
									</LoraHead>
									<LoraText loraCustomClass="margin-bottom-0@md">
										Technik
										<br />
										<a href="mailto:korycan@loraosvetleni.cz">korycan@loraosvetleni.cz</a>
									</LoraText>
								</div>
							</div>
							<div className="col col-12 col-6@lg">
								<div>
									<LoraHead loraElement="h3" loraColor="white" loraStyle="h5" loraCustomClass="margin-bottom-20">
										Radek Fryšták
									</LoraHead>
									<LoraText loraCustomClass="margin-bottom-0@md">
										Vývoj softwaru
										<br />
										<a href="mailto:frystak@loraosvetleni.cz">frystak@loraosvetleni.cz</a>
									</LoraText>
								</div>
							</div>
						</div>
						{/*<LoraHead loraElement="h2" loraColor="secondary" loraStyle="h3">
							Další partneři
						</LoraHead>
						<LoraHead loraElement="h3" loraColor="white" loraStyle="h5" loraCustomClass="margin-bottom-20">
							Solidus
						</LoraHead>
						<LoraHead loraElement="h3" loraColor="white" loraStyle="h5" loraCustomClass="margin-bottom-0">
							České radiokomunikace
						</LoraHead>*/}
					</div>
				</div>
			</main>
		</>
	);
};

export default ContactPage;
