import React, { FC } from "react";
import { Helmet } from "react-helmet";
import LoraHead from "../components/LoraHead";
import LoraButton from "../components/LoraButton";
import styles from "../styles/pages/PartnersPage.module.scss";
import { PAGE_NAME } from "../layout/config";

const hostname = window.location.protocol + "//" + window.location.hostname;

const PartnersPage: FC = () => {
	return (
		<>
			<Helmet>
				<title>Partneři | {PAGE_NAME}</title>
				<meta name="description" content="Partneři" />
				<meta property="og:title" content="Partneři | {PAGE_NAME}" />
				<meta property="og:description" content="Partneři" />
				<link rel="canonical" href={hostname + "/partneri"} />
				<link rel="alternate" hrefLang="cs-cs" href={hostname + "/partneri"} />
			</Helmet>

			<main className={styles.partners}>
				<div className="bg-primary padding-top-60 padding-bottom-60">
					<div className="container">
						<LoraHead loraElement="h1" loraColor="secondary" loraCustomClass="margin-bottom-60">
							Partneři
						</LoraHead>
						<div className="grid margin-bottom-60">
							<div className="col col-12 col-6@lg">
								<div>
									<LoraHead loraElement="h3" loraColor="white" loraStyle="h5" loraCustomClass="margin-bottom-20">
										Vývoj hardware
									</LoraHead>
									<LoraButton loraVariant="primary" path="//solidustech.cz" target="_blank" loraCustomClass="margin-bottom-0@md">
										{"Solidus Tech s.r.o."}
									</LoraButton>
								</div>
							</div>
							<div className="col col-12 col-6@lg">
								<div>
									<LoraHead loraElement="h3" loraColor="white" loraStyle="h5" loraCustomClass="margin-bottom-20">
										Výroba osvětlení
									</LoraHead>
									<LoraButton loraVariant="primary" path="//visiocom.cz" target="_blank" loraCustomClass="margin-bottom-0@md">
										{"Visiocom s.r.o."}
									</LoraButton>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

export default PartnersPage;
