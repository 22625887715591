import React, { FC, ReactElement, useLayoutEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import LoraHeader from "./layout/LoraHeader";
import LoraFooter from "./layout/LoraFooter";
import SolutionsPage from "./pages/SolutionsPage";
import ProductsPage from "./pages/ProductsPage";
import AboutUsPage from "./pages/AboutUsPage";
import ReferencesPage from "./pages/ReferencesPage";
import ForPartnersPage from "./pages/ForPartnersPage";
import ContactPage from "./pages/ContactPage";
import HomePage from "./pages/HomePage";
import StyleguidePage from "./pages/StyleguidePage";
import styles from "./styles/App.module.scss";
import PartnersPage from "./pages/PartnersPage";
import ProductDaliLightControlPage from "./pages/ProductDaliLightControlPage";

interface WrapperProps {
	children: ReactElement;
}

const Wrapper: FC<WrapperProps> = ({ children }) => {
	const location = useLocation();
	useLayoutEffect(() => {
		document.documentElement.scrollTo(0, 0);
	}, [location.pathname]);
	return children;
};

function App() {
	return (
		<Wrapper>
			<div className={styles.app}>
				<LoraHeader />
				<Routes>
					<Route path="/reseni" element={<SolutionsPage />} />
					<Route path="/produkty" element={<ProductsPage />} />
					<Route path="/produkty/dali-light-control" element={<ProductDaliLightControlPage />} />
					<Route path="/o-nas" element={<AboutUsPage />} />
					<Route path="/reference" element={<ReferencesPage />} />
					<Route path="/pro-partnery" element={<ForPartnersPage />} />
					<Route path="/partneri" element={<PartnersPage />} />
					<Route path="/kontakt" element={<ContactPage />} />
					<Route path="/styleguide" element={<StyleguidePage />} />
					<Route path="/" element={<HomePage />} />
					<Route path="*" element={<HomePage />} />
				</Routes>
				<LoraFooter />
			</div>
		</Wrapper>
	);
}

export default App;
