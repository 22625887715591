import React, { FC, ReactNode } from "react";
import styles from "../styles/components/LoraButton.module.scss";
import { NavLink } from "react-router-dom";
import clsx from "clsx";

type LoraButtonVariant = "primary" | "secondary" | "text-primary" | "text-secondary";

interface LoraButtonProps {
	children?: ReactNode;
	loraVariant?: LoraButtonVariant;
	loraCustomClass?: string;
	target?: string;
	path: string;
}

const LoraButton: FC<LoraButtonProps> = (props) => {
	const useVariant = () => {
		switch (props.loraVariant) {
			case "primary":
				return { buttonVariant: styles.primary };
			case "secondary":
				return { buttonVariant: styles.secondary };
			case "text-primary":
				return { buttonVariant: styles.text, buttonVariantColor: styles.textPrimary };
			case "text-secondary":
				return { buttonVariant: styles.text, buttonVariantColor: styles.textSecondary };
			default:
				return { buttonVariant: styles.default };
		}
	};

	let loraVariantClassName = useVariant()?.buttonVariant;
	let loraVariantColorClassName = useVariant()?.buttonVariantColor;

	return (
		<>
			<NavLink to={props.path} target={props.target} className={clsx(loraVariantClassName, loraVariantColorClassName, props.loraCustomClass)}>
				<span>{props.children}</span>
			</NavLink>
		</>
	);
};

export default LoraButton;
