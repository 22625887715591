import React, { FC, ReactNode } from "react";
import styles from "../styles/components/LoraText.module.scss";
import clsx from "clsx";

type LoraTextColor = "primary" | "secondary" | "white";
type LoraTextStyle = "nomargin" | "longtext";

interface LoraTextProps {
	children?: ReactNode;
	loraColor?: LoraTextColor;
	loraStyle?: LoraTextStyle;
	loraCustomClass?: string;
}

const LoraText: FC<LoraTextProps> = (props) => {
	const useColor = () => {
		switch (props.loraColor) {
			case "primary":
				return styles.primary;
			case "secondary":
				return styles.secondary;
			case "white":
				return styles.white;
		}
	};

	const useStyle = () => {
		switch (props.loraStyle) {
			case "nomargin":
				return styles.noMargin;
			case "longtext":
				return styles.longText;
			default:
				return styles.default;
		}
	};

	let loraTextColorClassName = useColor();
	let loraTextStyleClassName = useStyle();

	return (
		<>
			<p className={clsx(styles.text, loraTextColorClassName, loraTextStyleClassName, props.loraCustomClass)}>{props.children}</p>
		</>
	);
};

export default LoraText;
