import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import LoraLogo from "../components/LoraLogo";
import LoraMenu from "./LoraMenu";
import styles from "../styles/layout/LoraHeader.module.scss";

const LoraHeader: FC = (props) => {
	return (
		<>
			<header className={styles.header}>
				<div className="container">
					<div className={styles.wrap}>
						<div className={styles.logo}>
							<NavLink to="/" className={styles.logoLink}>
								<LoraLogo />
							</NavLink>
						</div>
						<div className={styles.menu}>
							<LoraMenu type="header" />
						</div>
						<div className={styles.response}>
							<LoraMenu type="responsive" />
						</div>
					</div>
				</div>
			</header>
		</>
	);
};

export default LoraHeader;
